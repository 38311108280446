/* This File is for {purpose} */

<template>
  <v-calendar
    class="custom-calendar max-w-full"
    :masks="masks"
    :attributes="attributes"
    disable-page-swipe
    is-expanded
  >
    <template #day-label>
      <span></span>
    </template>
    <template v-slot:day="{ day }">
      <v-card elevation="0" height="95%" class="ma-1 day-card">
        <v-layout wrap justify-start :key="day" fill-height pa-2>
          <v-flex xs12>
            <v-layout wrap justify-start :key="day" style="width: 100%">
              <v-flex xs6 text-left align-self-center class="content-txt text">
                {{ day }}
              </v-flex>
              <v-flex xs6 text-right pl-9>
                <v-switch
                  v-model="shiftSwitch"
                  inset
                  :ripple="false"
                  dense
                  hide-details
                />
              </v-flex>
            </v-layout>
          </v-flex>

          <V-flex xs4 align-self-center>
            <v-layout wrap justify-start>
              <v-flex xs6 pr-1 pb-1>
                <v-avatar color="#CFFAEA" size="20">
                  <span style="color: #1bae79; font-size: 14px">F</span>
                </v-avatar>
              </v-flex>
              <v-flex xs6 pb-1>
                <v-avatar color="#CFFAEA" size="20">
                  <span style="color: #1bae79; font-size: 14px">M</span>
                </v-avatar>
              </v-flex>
              <v-flex xs6 pr-1>
                <v-avatar color="#CFFAEA" size="20">
                  <span style="color: #1bae79; font-size: 14px">S</span>
                </v-avatar>
              </v-flex>
              <v-flex xs6>
                <v-avatar color="#CFFAEA" size="20">
                  <span style="color: #1bae79; font-size: 14px">N</span>
                </v-avatar>
              </v-flex>
            </v-layout>
          </V-flex>
        </v-layout>
      </v-card>
    </template>
  </v-calendar>
</template>

<script>
const month = new Date().getMonth();
const year = new Date().getFullYear();
export default {
  name: "ServiceCalendar",
  data: () => ({
    shiftSwitch: true,
    masks: {
      weekdays: "WWW",
    },
    attributes: [
      {
        key: 1,
        customData: {
          title: "Lunch with mom.",
          class: "bg-red-600 text-white",
        },
        dates: new Date(year, month, 1),
      },
      {
        key: 2,
        customData: {
          title: "Take Noah to basketball practice",
          class: "bg-blue-500 text-white",
        },
        dates: new Date(year, month, 2),
      },
      {
        key: 3,
        customData: {
          title: "Noah's basketball game.",
          class: "bg-blue-500 text-white",
        },
        dates: new Date(year, month, 5),
      },
      {
        key: 4,
        customData: {
          title: "Take car to the shop",
          class: "bg-indigo-500 text-white",
        },
        dates: new Date(year, month, 5),
      },
      {
        key: 4,
        customData: {
          title: "Meeting with new client.",
          class: "bg-teal-500 text-white",
        },
        dates: new Date(year, month, 7),
      },
      {
        key: 5,
        customData: {
          title: "Mia's gymnastics practice.",
          class: "bg-pink-500 text-white",
        },
        dates: new Date(year, month, 11),
      },
      {
        key: 6,
        customData: {
          title: "Cookout with friends.",
          class: "bg-orange-500 text-white",
        },
        dates: { months: 5, ordinalWeekdays: { 2: 1 } },
      },
      {
        key: 7,
        customData: {
          title: "Mia's gymnastics recital.",
          class: "bg-pink-500 text-white",
        },
        dates: new Date(year, month, 22),
      },
      {
        key: 8,
        customData: {
          title: "Visit great grandma.",
          class: "bg-red-600 text-white",
        },
        dates: new Date(year, month, 25),
      },
    ],
  }),
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
.day-card {
  background: #ffffff;
  box-shadow: 0 2px 4px #e4e9f5;
  border-radius: 8px;
}
.custom-calendar {
  background-color: #fafbff;
}
.v-calendar-weekly__head-weekday {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  letter-spacing: -0.02em !important;
  color: #0d061f !important;
}
</style>
