var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-calendar", {
    staticClass: "custom-calendar max-w-full",
    attrs: {
      masks: _vm.masks,
      attributes: _vm.attributes,
      "disable-page-swipe": "",
      "is-expanded": "",
    },
    scopedSlots: _vm._u([
      {
        key: "day-label",
        fn: function () {
          return [_c("span")]
        },
        proxy: true,
      },
      {
        key: "day",
        fn: function ({ day }) {
          return [
            _c(
              "v-card",
              {
                staticClass: "ma-1 day-card",
                attrs: { elevation: "0", height: "95%" },
              },
              [
                _c(
                  "v-layout",
                  {
                    key: day,
                    attrs: {
                      wrap: "",
                      "justify-start": "",
                      "fill-height": "",
                      "pa-2": "",
                    },
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          {
                            key: day,
                            staticStyle: { width: "100%" },
                            attrs: { wrap: "", "justify-start": "" },
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "content-txt text",
                                attrs: {
                                  xs6: "",
                                  "text-left": "",
                                  "align-self-center": "",
                                },
                              },
                              [_vm._v(" " + _vm._s(day) + " ")]
                            ),
                            _c(
                              "v-flex",
                              {
                                attrs: {
                                  xs6: "",
                                  "text-right": "",
                                  "pl-9": "",
                                },
                              },
                              [
                                _c("v-switch", {
                                  attrs: {
                                    inset: "",
                                    ripple: false,
                                    dense: "",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.shiftSwitch,
                                    callback: function ($$v) {
                                      _vm.shiftSwitch = $$v
                                    },
                                    expression: "shiftSwitch",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "V-flex",
                      { attrs: { xs4: "", "align-self-center": "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "", "justify-start": "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs6: "", "pr-1": "", "pb-1": "" } },
                              [
                                _c(
                                  "v-avatar",
                                  { attrs: { color: "#CFFAEA", size: "20" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#1bae79",
                                          "font-size": "14px",
                                        },
                                      },
                                      [_vm._v("F")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs6: "", "pb-1": "" } },
                              [
                                _c(
                                  "v-avatar",
                                  { attrs: { color: "#CFFAEA", size: "20" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#1bae79",
                                          "font-size": "14px",
                                        },
                                      },
                                      [_vm._v("M")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs6: "", "pr-1": "" } },
                              [
                                _c(
                                  "v-avatar",
                                  { attrs: { color: "#CFFAEA", size: "20" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#1bae79",
                                          "font-size": "14px",
                                        },
                                      },
                                      [_vm._v("S")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs6: "" } },
                              [
                                _c(
                                  "v-avatar",
                                  { attrs: { color: "#CFFAEA", size: "20" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#1bae79",
                                          "font-size": "14px",
                                        },
                                      },
                                      [_vm._v("N")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }