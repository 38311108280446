var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-start": "", "pt-4": "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs9: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "" } },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    md2: "",
                    "text-right": "",
                    "align-self-center": "",
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      placeholder: "July",
                      outlined: "",
                      dense: "",
                      "hide-details": "",
                    },
                  }),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    md3: "",
                    "align-self-center": "",
                    "text-right": "",
                  },
                },
                [
                  _c("AppNavButton", {
                    attrs: {
                      route: { name: "employees.add" },
                      "custom-class": "pa-5",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("pages.settings.global_settings")
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { staticStyle: { height: "70vh" }, attrs: { xs9: "", "pt-4": "" } },
        [_c("ServiceCalendar")],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs3: "", "pt-4": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pl-4": "" } },
            [
              _c("v-flex", { staticClass: "title-2", attrs: { xs12: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("pages.settings.assign_availability")) +
                    " "
                ),
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "", "pt-8": "" } },
                [
                  _c(
                    "v-card",
                    { attrs: { width: "80px", height: "80px", outlined: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "justify-start": "",
                            "fill-height": "",
                            "pa-2": "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "title-main",
                              attrs: {
                                xs12: "",
                                "align-self-center": "",
                                "text-center": "",
                              },
                            },
                            [_vm._v(" 16 ")]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "title-3",
                              attrs: { xs12: "", "text-center": "" },
                            },
                            [_vm._v(" July ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", "pt-4": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-start": "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "content-txt bold",
                          attrs: {
                            xs11: "",
                            "text-left": "",
                            "align-self-center": "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("form.early_shift")) + " ")]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs1: "" } },
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              ripple: false,
                              dense: "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.shiftSwitch,
                              callback: function ($$v) {
                                _vm.shiftSwitch = $$v
                              },
                              expression: "shiftSwitch",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "py-2": "" } },
                        [_c("v-divider")],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "content-txt bold",
                          attrs: {
                            xs11: "",
                            "text-left": "",
                            "align-self-center": "",
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("form.middle_shift")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs1: "" } },
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              ripple: false,
                              dense: "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.shiftSwitch,
                              callback: function ($$v) {
                                _vm.shiftSwitch = $$v
                              },
                              expression: "shiftSwitch",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "py-2": "" } },
                        [_c("v-divider")],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "content-txt bold",
                          attrs: {
                            xs11: "",
                            "text-left": "",
                            "align-self-center": "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("form.late_shift")) + " ")]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs1: "" } },
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              ripple: false,
                              dense: "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.shiftSwitch,
                              callback: function ($$v) {
                                _vm.shiftSwitch = $$v
                              },
                              expression: "shiftSwitch",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "py-2": "" } },
                        [_c("v-divider")],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "content-txt bold",
                          attrs: {
                            xs11: "",
                            "text-left": "",
                            "align-self-center": "",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("form.night_shift")) + " ")]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs1: "" } },
                        [
                          _c("v-switch", {
                            attrs: {
                              inset: "",
                              ripple: false,
                              dense: "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.shiftSwitch,
                              callback: function ($$v) {
                                _vm.shiftSwitch = $$v
                              },
                              expression: "shiftSwitch",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "py-2": "" } },
                        [_c("v-divider")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }