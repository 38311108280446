/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pt-4 fill-height>
    <v-flex xs9>
      <v-layout wrap justify-start>
        <v-flex xs12 md2 text-right align-self-center>
          <v-select placeholder="July" outlined dense hide-details></v-select>
        </v-flex>
        <v-spacer />
        <v-flex xs12 md3 align-self-center text-right>
          <AppNavButton :route="{ name: 'employees.add' }" custom-class="pa-5">
            <template #content>
              {{ $t("pages.settings.global_settings") }}
            </template>
          </AppNavButton>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs9 pt-4 style="height: 70vh">
      <ServiceCalendar />
    </v-flex>
    <v-flex xs3 pt-4>
      <v-layout wrap justify-start pl-4>
        <v-flex xs12 class="title-2">
          {{ $t("pages.settings.assign_availability") }}
        </v-flex>
        <v-flex xs12 pt-8>
          <v-card width="80px" height="80px" outlined>
            <v-layout wrap justify-start fill-height pa-2>
              <v-flex xs12 class="title-main" align-self-center text-center>
                16
              </v-flex>
              <v-flex xs12 class="title-3" text-center> July </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 pt-4>
          <v-layout wrap justify-start>
            <v-flex xs11 text-left align-self-center class="content-txt bold">
              {{ $t("form.early_shift") }}
            </v-flex>
            <v-flex xs1>
              <v-switch
                v-model="shiftSwitch"
                inset
                :ripple="false"
                dense
                hide-details
              />
            </v-flex>
            <v-flex xs12 py-2>
              <v-divider />
            </v-flex>
            <v-flex xs11 text-left align-self-center class="content-txt bold">
              {{ $t("form.middle_shift") }}
            </v-flex>
            <v-flex xs1>
              <v-switch
                v-model="shiftSwitch"
                inset
                :ripple="false"
                dense
                hide-details
              />
            </v-flex>
            <v-flex xs12 py-2>
              <v-divider />
            </v-flex>
            <v-flex xs11 text-left align-self-center class="content-txt bold">
              {{ $t("form.late_shift") }}
            </v-flex>
            <v-flex xs1>
              <v-switch
                v-model="shiftSwitch"
                inset
                :ripple="false"
                dense
                hide-details
              />
            </v-flex>
            <v-flex xs12 py-2>
              <v-divider />
            </v-flex>
            <v-flex xs11 text-left align-self-center class="content-txt bold">
              {{ $t("form.night_shift") }}
            </v-flex>
            <v-flex xs1>
              <v-switch
                v-model="shiftSwitch"
                inset
                :ripple="false"
                dense
                hide-details
              />
            </v-flex>
            <v-flex xs12 py-2>
              <v-divider />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import ServiceCalendar from "@/components/ServiceCalendar";
export default {
  name: "Service",
  components: { ServiceCalendar },
  data: () => ({
    shiftSwitch: true,
  }),
  mounted() {
    const breadCrumbs = [
      {
        name: "settings",
        text: "pages.settings.title",
      },
      {
        name: this.$route.name,
        text: "pages.settings.service",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
